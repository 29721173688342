// extracted by mini-css-extract-plugin
export var gridColumn = "p_4";
export var gridColumnLargeSpacing = "p_5";
export var gridL12 = "p_bt";
export var gridL3 = "p_bl";
export var gridL4 = "p_bm";
export var gridL5 = "p_bn";
export var gridL6 = "p_bp";
export var gridL7 = "p_bq";
export var gridL8 = "p_br";
export var gridL9 = "p_bs";
export var gridM12 = "p_bk";
export var gridM3 = "p_bd";
export var gridM4 = "p_bf";
export var gridM6 = "p_bg";
export var gridM8 = "p_bh";
export var gridM9 = "p_bj";
export var gridRow = "p_2";
export var gridRowMaxContentWidth = "p_3";
export var gridS12 = "p_bc";
export var gridS3 = "p_6";
export var gridS4 = "p_7";
export var gridS6 = "p_8";
export var gridS8 = "p_9";
export var gridS9 = "p_bb";
export var gutterBottom = "p_T";